import { graphql, Link } from "gatsby"
import { sortBy } from "lodash"
import React from "react"
import { Footer } from "../components/Footer"
import { Header } from "../components/Header"
import "../css"
// import "../css/bootstrap.ltr.min.css"
import "../scss/index.scss"
import locales from "../translate/locales"

export const query = graphql`
  query($locale: String!) {
    allContentfulBlogPosts(filter: { language: { eq: $locale } }) {
      edges {
        node {
          id
          title
          description
          postDate
          postType
          image {
            file {
              url
            }
          }
        }
      }
    }
  }
`

const blog = (props: any) => {
  const locale = props.pageContext.locale
  const texts = locales[locale as "en"]

  const pageData = props.data.allContentfulBlogPosts.edges

  const newsFeed = sortBy(
    pageData.map((feed: any) => {
      return {
        ...feed,
      }
    }),
    "postDate"
  )

  const translate = texts.blogPageContent

  return (
    <div>
      <Header lang={locale} />

      <div
        className="page-title bg_cover mt-80 pt-140 pb-140"
        style={{ backgroundImage: `url(/images/pexels-photo-1591056.jpeg)` }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              <div className="page-title-item text-center">
                <h3 className="title">{translate.news} </h3>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      <a href="#">{translate.home}</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {translate.blog}
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section className="blog-standard-area gray-bg pt-80 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="blog-standard">
                <div className="row justify-content-center">
                  {newsFeed.map((blog: any) => {
                    return (
                      <div className="col-lg-6 col-md-6 col-sm-9">
                        <div className="single-blog-grid mt-40">
                          <div className="blog-thumb">
                            <img
                              src={
                                blog.node.image !== null
                                  ? blog.node.image.file.url
                                  : "/images/blogNotSelect.jpeg"
                              }
                              alt="blog"
                            />
                          </div>
                          <div className="blog-content">
                            <span>{blog.node.postType}</span>

                            <Link to={`/${locale}/blogView/${blog.node.id}`}>
                              <h4 className="title">{blog.node.title}</h4>
                            </Link>

                            <p>{blog.node.description}</p>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="blog-sidebar ml-10">
                <div className="blog-side-about white-bg mt-40">
                  <div className="about-title">
                    <h4 className="title">{translate.search}</h4>
                  </div>
                  <div className="blog-Search-content text-center">
                    <form action="#">
                      <div className="input-box">
                        <input
                          type="text"
                          placeholder={translate.searchKeyWord}
                          style={{ paddingRight: 10 }}
                        />
                        <button
                          type="button"
                          style={locale == "ar" ? { left: 0 } : { right: 0 }}
                        >
                          <i className="far fa-search"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="blog-side-about white-bg mt-40">
                  <div className="about-title">
                    <h4 className="title">{translate.newsFeeds}</h4>
                  </div>
                  <div className="blog-feeds-content mt-10" s>
                    {newsFeed.map((post: any) => {
                      return (
                        <div className="single-feeds d-flex">
                          <div className="feeds-thumb mr-20">
                            <img
                              src={
                                post.node.image !== null
                                  ? post.node.image.file.url
                                  : "/images/blogNotSelect.jpeg"
                              }
                              alt="feeds"
                            />
                          </div>
                          <div className="feeds-content">
                            <Link to={`/blogView/${post.node.id}`}>
                              <h6 className="title">{post.node.title}</h6>
                            </Link>

                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>

                <div className="blog-side-about white-bg mt-40">
                  <div className="about-title">
                    <h4 className="title">{translate.neverMissNews}</h4>
                  </div>
                  <div className="blog-social-content mt-35 text-center">
                    <ul>
                      <li>
                        <a href="#">
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-twitter"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-behance"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-linkedin-in"></i>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-youtube"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer lang={locale} />
    </div>
  )
}

export default blog
